<template>
	<div class="d-flex flex-column h-100 bg-light">
		<main class="flex-shrink-0">
			<div class="container px-5 my-5">
				<div class="text-center mb-5">
					<h1 class="display-5 fw-bolder mb-0">
						<span class="text-gradient d-inline">mon CV</span>
					</h1>
				</div>
				<div class="row gx-5 justify-content-center">
					<div class="col-lg-11 col-xl-9 col-xxl-8">
						<!-- Experience Section-->
						<section>
							<div
								class="d-flex align-items-center justify-content-between mb-4"
							>
								<h3 class="text-primary fw-bolder mb-0">
									Expériences professionnelles
								</h3>

								<a
									class="btn btn-gradient px-3 py-1"
									href="/pix/cv/cv_resume.pdf"
								>
									<div
										class="d-inline-block bi bi-download me-2"
									></div>
									<small>
										CV&nbsp;&nbsp;|&nbsp;&nbsp;Resume</small
									>
								</a>
							</div>
							<!-- Experience Card 1-->
							<div
								class="card shadow border-0 rounded-4 mb-5"
							>
								<div class="card-body p-5">
									<div
										class="row align-items-center gx-5"
									>
										<div
											class="col text-center text-lg-start mb-4 mb-lg-0"
										>
											<div
												class="bg-light p-4 rounded-4"
											>
												<div
													class="text-primary fw-bolder mb-2"
												>
													Depuis mars
													2024
												</div>
												<div
													class="small fw-bolder"
												>
													Développeur web
												</div>
												<div
													class="small text-muted"
												>
													Ifocop
												</div>
												<div
													class="small text-muted"
												>
													Paris 11me
												</div>
											</div>
										</div>
										<div class="col-lg-8">
											<div>
												<h6>
													Refonte du LMS
													sous Moodle
												</h6>

												<ul>
													<li>
														Scripts JS
													</li>
													<li>
														Templating
														Html
													</li>
													<li>
														Css
														Responsive
													</li>
													<li>
														Accessibilité
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- Experience Card 3-->
							<div
								class="card shadow border-0 rounded-4 mb-5"
							>
								<div class="card-body p-5">
									<div
										class="row align-items-center gx-5"
									>
										<div
											class="col text-center text-lg-start mb-4 mb-lg-0"
										>
											<div
												class="bg-light p-4 rounded-4"
											>
												<div
													class="text-primary fw-bolder mb-2"
												>
													2020 - 2023
												</div>
												<div
													class="small fw-bolder"
												>
													Conception de
													sites internet
												</div>
												<div
													class="small text-muted"
												>
													Entreprises et
													particuliers
												</div>
												<div
													class="small text-muted"
												>
													Paris, Puteaux,
													Neuilly
												</div>
											</div>
										</div>
										<div class="col-lg-8">
											<div>
												<h6>
													Conception de
													sites internet
												</h6>

												<ul>
													<li>
														Visibilité
														et
														performance.
													</li>

													<li>
														Adaptation
														pour des
														designs
														responsives
													</li>
													<li>
														Stratégies
														SEO |
														Référencement
														naturel
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- Experience Card 4-->

							<div
								class="card shadow border-0 rounded-4 mb-5"
							>
								<div class="card-body p-5">
									<div
										class="row align-items-center gx-5"
									>
										<div
											class="col text-center text-lg-start mb-4 mb-lg-0"
										>
											<div
												class="bg-light p-4 rounded-4"
											>
												<div
													class="text-primary fw-bolder mb-2"
												>
													2017 - 2022
												</div>
												<div
													class="small fw-bolder"
												>
													Création de
													tutoriels vidéo
												</div>
												<div
													class="small text-muted"
												>
													Indépendant
												</div>
												<div
													class="small text-muted"
												>
													Puteaux,
													Neuilly
												</div>
											</div>
										</div>
										<div class="col-lg-8">
											<div>
												<h6>
													Plan, écriture,
													réalisation et
													montage.
												</h6>

												<ul>
													<li>
														Maintient
														de
														l'engagement
														des
														utilisateurs
													</li>

													<li>
														Réponses
														aux
														questions
														des
														apprenants
													</li>
													<li>
														Adaptant
														des
														contenus
														aux
														besoins
														spécifiques
														des
														participants
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- Experience Card 4-->

							<div
								class="card shadow border-0 rounded-4 mb-5"
							>
								<div class="card-body p-5">
									<div
										class="row align-items-center gx-5"
									>
										<div
											class="col text-center text-lg-start mb-4 mb-lg-0"
										>
											<div
												class="bg-light p-4 rounded-4"
											>
												<div
													class="text-primary fw-bolder mb-2"
												>
													2013 - 2022
												</div>
												<div
													class="small fw-bolder"
												>
													Formateur web |
													graphisme
												</div>
												<div
													class="small text-muted"
												>
													Formateur
													indépendant
												</div>
												<div
													class="small text-muted"
												>
													Ellipse
													formations
												</div>
												<div
													class="small text-muted"
												>
													Puteaux,
													Neuilly
												</div>
											</div>
										</div>
										<div class="col-lg-8">
											<div>
												<h6>
													Plan, écriture,
													réalisation et
													montage.
												</h6>

												<ul>
													<li>
														Conception
														et
														animation
														de
														formations
														destinée à
														des
														groupes,
														en
														présentiel
														et en
														ligne
													</li>

													<li>
														Accompagnement
														des
														apprenants
														dans la
														réalisation
														de projets
														concrets
													</li>
													<li>
														Adaptations
														des
														contenus
														aux
														besoins
														spécifiques
														des
														participants
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<!-- Divider-->
						<div class="pb-5"></div>
						<!-- Skills Section-->
						<section>
							<!-- Skillset Card-->
							<div
								class="card shadow border-0 rounded-4 mb-5"
							>
								<div class="card-body p-5">
									<!-- Professional skills list-->
									<div class="mb-5">
										<div
											class="d-flex align-items-center mb-4"
										>
											<div
												class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"
											>
												<i
													class="bi bi-tools"
												></i>
											</div>
											<h3
												class="fw-bolder mb-0"
											>
												<span
													class="text-gradient d-inline"
													>Compétences</span
												>
											</h3>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3 mb-4"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Développement
													Web
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Gestion de
													Versions
												</div>
											</div>
											<div class="col">
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Vidéo | Audio
												</div>
											</div>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Base de Données
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Graphisme
												</div>
											</div>
											<div class="col">
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Création de
													Contenu
												</div>
											</div>
										</div>
									</div>
									<!-- Languages list-->
									<div class="mb-0">
										<div
											class="d-flex align-items-center mb-4"
										>
											<div
												class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"
											>
												<i
													class="bi bi-code-slash"
												></i>
											</div>
											<h3
												class="fw-bolder mb-0"
											>
												<span
													class="text-gradient d-inline"
													>Langages |
													Technologies
												</span>
											</h3>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3 mb-4"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													HTML 5
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													CSS
												</div>
											</div>
											<div class="col">
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													JavaScript
												</div>
											</div>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													MongoDB
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Express
												</div>
											</div>
											<div class="col">
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Node.js
												</div>
											</div>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3 mt-4"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Git
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Bootstrap 5
												</div>
											</div>
											<div class="col">
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Ajax
												</div>
											</div>
											<div class="col mt-4">
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Interfaces API
												</div>
											</div>
										</div>
									</div>
									<!-- Languages list-->
									<div class="mb-0 mt-5">
										<div
											class="d-flex align-items-center mb-4"
										>
											<div
												class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"
											>
												<i
													class="bi bi-code-slash"
												></i>
											</div>
											<h3
												class="fw-bolder mb-0"
											>
												<span
													class="text-gradient d-inline"
													>FrameWorks</span
												>
											</h3>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3 mb-4"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Vue JS
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Angular
												</div>
											</div>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3"
										></div>
									</div>
									<div class="mb-0 mt-5">
										<div
											class="d-flex align-items-center mb-4"
										>
											<div
												class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"
											>
												<i
													class="bi bi-code-slash"
												></i>
											</div>
											<h3
												class="fw-bolder mb-0"
											>
												<span
													class="text-gradient d-inline"
													>Logiciels</span
												>
											</h3>
										</div>
										<div
											class="row row-cols-1 row-cols-md-3 mb-4"
										>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Visual Studio
													Code
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Bruno
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													Photoshop
												</div>
											</div>
											<div
												class="col mb-4 mb-md-0 mt-4"
											>
												<div
													class="d-flex align-items-center bg-light rounded-4 p-3 h-100"
												>
													LightRoom
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import CommonHead from './CommonHead.vue';

export default {
	name: 'Cv',

	data() {
		return {
			// Vos données ici
		};
	},
	methods: {
		// Vos méthodes ici
	},
	computed: {
		// Vos propriétés calculées ici
	},
	watch: {
		// Vos observateurs ici
	},
};
</script>

<style scoped>
.btn-gradient {
	background-image: linear-gradient(45deg, #7132b4, #4377d2);
	color: white; /* Pour le contraste du texte */
	border: none; /* Supprimer les bordures */
}

/* Facultatif : Pour ajouter un effet de survol */
.btn-gradient:hover {
	background-image: linear-gradient(to right, #2575fc, #6a11cb);
}
/* Votre style ici */
</style>
